










































import { Component, Mixins, Watch } from 'vue-property-decorator'

import VLoadSpinner from '~/components/VLoadSpinner.vue'
import { Dropdowns } from '~/mixins/dropdowns'
import { createAmplifyPlayerApi, fetchAmplifyPlayerApi, removeAmplifyPlayerApi, updateAmplifyPlayerApi } from '#/api/amplify-player'
import { IModalParams, ModalStore, ModalType } from '~/store/modal'
import { UserStore } from '#/store/user'
import AlertBoxSubscription from '#/components/AlertBoxSubscription.vue'
// @ts-ignore
import Verte from 'verte'
import 'verte/dist/verte.css'

@Component({ components: { VLoadSpinner, AlertBoxSubscription, Verte } })
export default class AmplifyPlayerPage extends Mixins(Dropdowns) {
    isLoading = true
    amplifyPlayer: IAmplifyPlayer = null

    @ModalStore.Mutation('SET_ACTIVE_MODAL') SET_ACTIVE_MODAL: (modalParams: IModalParams) => void
    @UserStore.Getter('hasEliteSubscription') hasEliteSubscription: boolean

    get embeddableCodeToShare() {
        return `<iframe src="https://embed.thecharts.com/${this.amplifyPlayer.id}" width="750" height="510" frameborder="0" scrolling="no"></iframe>`
    }

    @Watch('amplifyPlayer', { deep: true })
    amplifyPlayerChanged(newValue: IAmplifyPlayer) {
        // @ts-ignore
        this.$refs.amplifyPlayerFrame?.contentWindow.postMessage({ color_primary: newValue.color_primary }, 'https://embed.thecharts.com')
    }

    async fetchAmplifyPlayer() {
        this.isLoading = true
        const amplifyPlayers = await fetchAmplifyPlayerApi()
        this.amplifyPlayer = amplifyPlayers[0]
        this.isLoading = false
    }

    async saveAmplifyPlayer() {
        // api calls
        try {
            if (!this.amplifyPlayer) {
                await createAmplifyPlayerApi({ color_primary: '#e0012e' })
                this.fetchAmplifyPlayer()
            } else await updateAmplifyPlayerApi(this.amplifyPlayer)
            this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
        } catch (err) {
            this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
        }
    }

    async removeAmplifyPlayer() {
        try {
            if (this.amplifyPlayer.id) await removeAmplifyPlayerApi(this.amplifyPlayer.id)
            this.SET_ACTIVE_MODAL({ modal: ModalType.Success })
            this.amplifyPlayer = null
        } catch (err) {
            this.SET_ACTIVE_MODAL({ modal: ModalType.Error })
        }
    }

    openDeleteConfirmActionModal() {
        this.SET_ACTIVE_MODAL({ modal: ModalType.ConfirmAction, callback: () => this.removeAmplifyPlayer() })
    }

    mounted() {
        this.fetchAmplifyPlayer()
    }
}
