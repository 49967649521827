import { get, post, remove, update } from '~/services/http-requests'

const _BS_AMPLIFY_PLAYER = 'embeddable_store'

export function fetchAmplifyPlayerApi(): Promise<IAmplifyPlayer[]> {
    return get({ baseProperty: _BS_AMPLIFY_PLAYER })
}

export function createAmplifyPlayerApi(amplifyPlayer: Partial<IAmplifyPlayer>): Promise<IAmplifyPlayer> {
    return post({ baseProperty: _BS_AMPLIFY_PLAYER, fullUrl: `${_BS_AMPLIFY_PLAYER}/`, dispatchParams: amplifyPlayer })
}

export function updateAmplifyPlayerApi(amplifyPlayer: Partial<IAmplifyPlayer>): Promise<IAmplifyPlayer> {
    return update({ baseProperty: _BS_AMPLIFY_PLAYER, fullUrl: `${_BS_AMPLIFY_PLAYER}/${amplifyPlayer.id}/`, dispatchParams: amplifyPlayer })
}

export function removeAmplifyPlayerApi(id: IAmplifyPlayer['id']): Promise<void> {
    return remove({ baseProperty: _BS_AMPLIFY_PLAYER, fullUrl: `${_BS_AMPLIFY_PLAYER}/${id}/`})
}
